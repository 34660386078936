import { Actions } from '../interfaces'
import employee from './employee'
import employeePosition from './employeePosition'
import positionType from './positionType'

export const Models = {
  ...employee.Model,
  ...positionType.Model,
  ...employeePosition.Model,
}

export const actions: Record<string, Actions> = {
  ...employee.actions,
  ...positionType.actions,
  ...employeePosition.actions,
}

export const schema = { Models, actions }
export default schema
