import { Transform, Type } from 'class-transformer'
import { Entity } from '..'
import dayjs, { Dayjs } from 'dayjs'
import { Employee } from './Employee'
import { PositionType } from './PositionType'

export class EmployeePosition extends Entity {  
  @Type(() => Dayjs)
  @Transform(({ value }) => dayjs(value), { toClassOnly: true })
  dateFrom: Dayjs;

  @Type(() => Dayjs)
  @Transform(({ value }) => dayjs(value), { toClassOnly: true })
  dateTo: Dayjs;

  @Type(() => Employee)
  employee?: Employee;

  @Type(() => PositionType)
  positionType: PositionType;

  idPositionType: number
  idEmployee: number
}