import { EmployeePosition } from '@/entities/hr'
import { Actions } from '../../interfaces'
import find from './find'
import create from './create'
import update from './update'

export const actions: Actions = {
  find,
  create,
  update,
}

export default {
  Model: { EmployeePosition },
  actions: { [EmployeePosition.name]: actions },
}
