import { Type } from 'class-transformer'
import { Entity } from '..'

import { PaymentRecipient } from './PaymentRecipient'
import { PaymentOrder } from './PaymentOrder'
import { ProcessExpense } from '@/entities/settings'
import { fixPrice, parseToNumber } from '@/utils/general'

export class PaymentOrderItem extends Entity {
  @Type(() => PaymentRecipient)
  recipients: PaymentRecipient[]

  @Type(() => PaymentOrder)
  order: PaymentOrder

  @Type(() => ProcessExpense)
  processExpense: ProcessExpense

  amount: number

  get title () {
    const { expense } = this

    return `${expense?.description}`
  }

  get name () {
    const { expense } = this
    return expense?.name
  }

  get expense () {
    const { processExpense } = this

    return processExpense?.expense
  }

  get paymentRecipients () {
    const { recipients } = this

    return recipients?.map(recipient => {
      return { payments: recipient.payments, person: recipient.person, id: recipient.id }
    })
  }

  get expenses () {
    const { paymentRecipients } = this

    return paymentRecipients?.reduce((acc, recipient) => {
      return acc + recipient?.payments.reduce((acc, payment) => {
        return acc + parseToNumber(payment?.amount || 0)
      }, 0)
    }, 0)
  }

  get paymentRecipientsTitle () {
    const { paymentRecipients, expense } = this

    return paymentRecipients?.flatMap(recipient => {
      return recipient.payments.map(payment => {
        const paymentType = payment?.type?.description.replace(/Tarjeta/i, 'T. ')

        return {
          title: `${recipient?.person?.name} / ${paymentType} / ${fixPrice(payment?.amount)}`,
          id: payment?.id,
          type: expense.name,
          status: payment?.status,
          financialAccount: payment?.financialAccount,
          person: recipient.person,
          account: payment.recipientAccount,
          amount: payment.amount,
          paymentType: payment.type,
          paymentRecipient: recipient,
          backup: payment?.paymentBackup,
          expenseType: expense.description,
        }
      })
    })
  }
}
