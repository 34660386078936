import { PositionType } from '@/entities/hr'
import { Actions } from '../../interfaces'
import fetch from './fetch'
import find from './find'
import create from './create'
import update from './update'
import remove from './remove'

export const actions: Actions = {
  fetch,
  find,
  create,
  update,
  remove,
}

export default {
  Model: { PositionType },
  actions: { [PositionType.name]: actions },
}
