import gql from 'graphql-tag'

import { AggregatePath } from '@/entities/interfaces'
import { StockFragment, StockViewDetailFragment } from '@/graphql/fragments/sales'
import {
  BaseAppraisalFragment,
  BaseEnablementFragment,
  BaseInspectionFragment,
  ResponseFragment,
} from '@/graphql/fragments/purchase'
import { BaseDealFragment } from '@/graphql/fragments/crm'
import { BaseAutoFragment } from '@/graphql/fragments/public'
import { AttributeFragment, BaseAutoMotiveRegistrationFragment, VersionYearFragment } from '@/graphql/fragments/vehicle'
import { MileagePriceFragment } from '@/graphql/fragments/vehicle/mileagePrice'
import { DealAutoAttributeFragment } from '@/graphql/fragments/public/dealAutoAttribute'

export const query = gql`
  ${StockFragment}
  ${BaseEnablementFragment}
  ${BaseInspectionFragment}
  ${BaseAppraisalFragment}
  ${BaseDealFragment}
  ${BaseAutoFragment}
  ${VersionYearFragment}
  ${BaseAutoMotiveRegistrationFragment}
  ${MileagePriceFragment}
  ${ResponseFragment}
  ${AttributeFragment}
  ${DealAutoAttributeFragment}
  ${StockViewDetailFragment}
  query fetchStock($id: Int!) {
    records: sales_stock_by_pk(id: $id) {
      ...Stock
      soldDate: sold_date
      dailies: stock_dailies(order_by: { id: desc }) {
        id
        reportDate: report_date
        status: process_status {
          id
          status {
            id
            name
          }
        }
      }
      deals {
        id
        createdAt: created_at
        id
        closingReason: closing_reason {
          id
        }
        lead {
          id
          deals {
            channel {
              id
              name
              description
            }
          }
          initialChannel: channel {
            id
            name
            description
          }
          pipeline {
            id
            name
            description
          }
        }
      }
      viewDetails: stock_detail {
        ...StockViewDetail
      }
      active: deals_aggregate(where: {id_closing_reason: {_is_null: true}}) {
        aggregate {
          total: count(distinct: true, columns: id)
        }
      }
    }
  }
`

export const aggregates: Array<AggregatePath> = [
  { name: 'active', aggregations: ['total'] },
]

export default { query, aggregates }
