import gql from 'graphql-tag'
import { EntityFields, EnumFields } from '../shared'

export const PositionTypeFragment = gql`
  fragment PositionType on hr_position_type {
    ${EntityFields}
    ${EnumFields}
    active
  }
`
