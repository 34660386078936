import gql from 'graphql-tag'

export const mutation = gql`
  mutation insertPositionType($fields: hr_position_type_insert_input!) {
    record: insert_hr_position_type_one(object: $fields) {
      id
    }
  }
`

export default mutation
