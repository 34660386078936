import gql from 'graphql-tag'
import { PositionTypeFragment } from '@/graphql/fragments/hr'

export const query = gql`
  ${PositionTypeFragment}
  query findPositionType($filter: hr_position_type_bool_exp, $limit: Int, $order: [hr_position_type_order_by!]) {
    records: hr_position_type (where: $filter, limit: $limit, order_by: $order) {
      ...PositionType
    }
  }
`
export default query
