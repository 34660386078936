import gql from 'graphql-tag'
import { EntityFields } from '../shared'

export const EmployeePositionFragment = gql`
  fragment EmployeePosition on hr_employee_position {
    ${EntityFields}
    idPositionType: id_position_type
    idEmployee: id_employee
  }
`
