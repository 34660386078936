import { Entity } from '..'

export class PositionType extends Entity {
  name: string
  description: string
  active: boolean

  get formattedName () {
    const { name } = this

    return name.charAt(0).toUpperCase() + name.slice(1, name.length).toLocaleLowerCase()
  }

  get fullName () {
    const { formattedName, active } = this

    if (active) {
      return formattedName
    }
    return `${formattedName} (inactivo)`
  }
}
   