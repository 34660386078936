import { Actions, Aggregates } from '../../interfaces'
import { Stock } from '@/entities/sales'

import find from './find'
import fetch from './fetch'
import update from './update'
import create from './create'
import findLite from './findLite'
import fetchSpecial from './fetchSpecial'

export const actions: Actions = {
  find,
  fetch: fetch.query,
  fetchSpecial: fetchSpecial.query,
  update,
  create,
  findLite,
}

export const aggregates: Aggregates = {
  fetch: fetch.aggregates,
}

export default {
  Model: { Stock },
  actions: { [Stock.name]: actions },
  aggregates: { [Stock.name]: aggregates },
}
