import gql from 'graphql-tag'
import { EmployeePositionFragment, PositionTypeFragment } from '@/graphql/fragments/hr'

export const query = gql`
  ${EmployeePositionFragment}
  ${PositionTypeFragment}
  query findEmployeePosition($filter: hr_employee_position_bool_exp, $limit: Int, $order: [hr_employee_position_order_by!]) {
    records: hr_employee_position (where: $filter, limit: $limit, order_by: $order) {
      ...EmployeePosition
      dateFrom: date_from
      dateTo: date_to
      positionType: position_type {
        ...PositionType
      }
    }
  }
`
export default query
