import { PositionTypeFragment } from '@/graphql/fragments/hr'
import gql from 'graphql-tag'

export const query = gql`
  ${PositionTypeFragment}
  query fetchPositionType($id: Int!) {
    records: hr_position_type_by_pk(id: $id) {
      ...PositionType
    }
  }
`

export default query
